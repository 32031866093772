<template>
  <div>
    <el-button @click="getApplicationCarList" size="small">刷新</el-button>
    <el-button @click="handleAdd" type="primary" size="small">新增</el-button>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="申请时间" prop="created_at"></el-table-column>
      <el-table-column label="出发时间" prop="dispatch_time"></el-table-column>
      <el-table-column label="返回时间" prop="return_time"></el-table-column>
      <el-table-column label="用车事由" prop="apply_reason"></el-table-column>
      <el-table-column label="目的地" prop="target"></el-table-column>
      <el-table-column label="状态" v-slot="{ row }">
        <el-tag size="small" effect="dark"
          :type="applicationStatusDict[row.status].type">{{ applicationStatusDict[row.status].name }}</el-tag>
      </el-table-column>
      <el-table-column label="审批意见" prop="remark"></el-table-column>
    </el-table>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog title="用车申请" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="出发时间" prop="dispatch_time">
          <el-date-picker v-model="form.dispatch_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="返回时间" prop="return_time">
          <el-date-picker v-model="form.return_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用车事由" prop="apply_reason">
          <el-input v-model="form.apply_reason"></el-input>
        </el-form-item>
        <el-form-item label="目的地" prop="target">
          <el-input v-model="form.target"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getApplicationCarListAPI,
    applicationCarAPI
  } from '@/api/dispatch-vehicle.js'

  export default {
    name: 'ApplicationCar',
    data() {
      return {
        queryForm: {
          page: 1,
          page_size: 10
        },

        applicationStatusDict: {
          1: {
            name: '待安排',
            type: 'warning'
          },
          2: {
            name: '已安排',
            type: ''
          },
          3: {
            name: '审批通过',
            type: 'success'
          },
          4: {
            name: '审批驳回',
            type: 'danger'
          },
          5: {
            name: '已出营',
            type: 'warning'
          },
          6: {
            name: '已回营',
            type: 'info'
          }
        },

        showDialog: false,
        submitting: false,
        form: {
          dispatch_time: '',
          return_time: '',
          apply_reason: '', // 用车事由
          target: '',
        },
        rules: {
          dispatch_time: [{
            required: true,
            message: '出发时间不能为空',
            trigger: 'change'
          }],
          return_time: [{
            required: true,
            message: '返回时间不能为空',
            trigger: 'blur'
          }],
          target: [{
            required: true,
            message: '目的地不能为空',
            trigger: 'change'
          }],
          apply_reason: [{
            required: true,
            message: '用车事由不能为空',
            trigger: 'change'
          }],
        },

        tableData: [],
        tableLoading: false,

        // 用户档案
        userRecords: [],
        userLoadig: false,
      }
    },
    created() {
      this.getApplicationCarList()
    },
    methods: {
      handleAdd() {
        this.showDialog = true
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true

          applicationCarAPI(this.form).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getApplicationCarList()
          }).finally(() => this.submitting = false)
        })
      },
      getApplicationCarList() {
        this.tableLoading = true

        getApplicationCarListAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>